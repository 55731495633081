
@page {
    margin-top: 0;
    margin-bottom: 0;
padding-top: 200px;
  }
.invoice-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 20px auto;
    background-color: white;
    
  }
  
  .header2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    width: 100px; /* Adjust size as needed */
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: unsent;
    margin-top: 5px;
    overflow-x: auto;
  }
  
  .invoice-table th,
  .invoice-table td {
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .totals {
    margin-top: 20px;
  }
  